<!-- Default  theme -->
<template>
  <div class="carousel-wrap">
    <b-carousel
      v-model="slide"
      :interval="4000"
      controls
      indicators
      background="#ababab"

      style="text-shadow: 1px 1px 2px #5f5f5f"

    >
      <b-carousel-slide v-for="(image,index) in themePreviewImages" :key="index"
        :src="image"
        class="w-100"
      >
        <template #img>
          <div class="img-div">
          <img
            class="d-block img-fluid w-100"
            :src="image"
            alt="image slot"
          />
        </div>
        </template>
      </b-carousel-slide>
    </b-carousel>
  </div>
</template>
  
  <script>
export default {
  name: "CarsouleTheme",
  props: {
    themePreviewImages: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      slide: 0,
    };
  },
};
</script>
  <style>
  .img-div{
   /* max-width: 250px; */
    max-height: 450px;
  }
  .img-div img{
    width: 100% !important;
    height: 100% !important;
    object-fit: cover;
  }
</style>