<template>
  <div>
    <div>
      <b-modal
       :id="theme.id.toString()"
      
        hide-footer
        v-model="modalShow"
        size="lg"
      >
        <template #modal-header>
          <div class="view-clients-header">
            <b-button variant="close-btn" @click="hideModal">
              <i class="fas fa-times"></i>
            </b-button>
          </div>
        </template>
        <b-container fluid>
          <div class="">
            <carsoule-theme :themePreviewImages="theme.theme_preview.image"/>
            <div class="theme-detail">
              <h6 class="mb-3">Theme : {{ theme.name }}</h6>
              <div class="">
                <h6> Description</h6>
                <p>
                 {{ theme.description }}
                </p>
              </div>
              <h6> Date: {{ theme.created_at }}</h6>

            </div>
            <!-- <div v-if="!theme.isAssigned" class="d-flex justify-content-center">
              <div class="activate-btn text-center activate-preview" @click="activateTheme">
                Activate
              </div>
            </div> -->
            <div class="d-flex justify-content-center">
            <b-button v-if="!theme.isAssigned"
                  class=" text-center activate-preview activate-btn"
                  variant="success"
                  @click="activateTheme(theme)"
                >
                  Activate
                </b-button>
          </div>
        </div>
        </b-container>
      </b-modal>
    </div>
  </div>
</template>

<script>
import CarsouleTheme from "./CarsouleTheme.vue";
export default {
  name: "PreviewModal",
  components: { CarsouleTheme },
  props: {
    theme: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      modalShow: false,
    };
  },
  methods: {
    activateTheme() {
      this.$emit("activate-theme", this.theme);
      this.hideModal();
    },
    hideModal() {
      this.modalShow = false;
    },
  },
};
</script>

