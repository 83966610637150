<template>
  <div class="page-wrapper accInfo-wrap">
    <div class="container-fluid">
      <!-- <breadcrumb :items="breadcrumbItems" /> -->
      <div class="d-flex justify-content-between align-item-center">
        <div class="">
          <div class="page-title">
            <h1>Select Rep Registration Theme</h1>
          </div>
        </div>
      </div>
      <div class="card-bg">
        <div class="d-flex justify-content-between">
          <div v-for="theme in themeList" :key="theme.id" class="me-4">
            <div :class="[theme.isAssigned ? getThemeCardClass(theme) : '', 'theme-card default-theme']">
              <div class="theme-card">
                <div class="backdrop"></div>
                <img :src="theme.thumbnail" class="w-100" />
                <b-button v-b-modal="theme.id.toString()" class="button-preview"
                  >Preview</b-button
                >
              </div>
              <div class="theme-name">
                <div>{{ theme.name }}</div>

                <b-button v-if="!theme.isAssigned"
                  class="activate-btn"
                  variant="success"
                  @click="activateTheme(theme)"
                >
                  Activate
                </b-button>
                <div class="theme__active" v-if="theme.isAssigned">Active</div>
              </div>
            </div>
            <preview-modal :theme="theme" @activate-theme="activateTheme"/>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import PreviewModal from "./PreviewModal.vue";

export default {
  components: { PreviewModal },
  name: "SelectTheme",
  data() {
    return {
      themeList: [], 
    };
  },
  methods: {
    getThemeData(){
    let loader= this.$loading.show();
    axios.get('v2/theme-configuration/get-theme').then(response=>{
      this.themeList=response.data.data;
    }).catch(err=>{
      console.log(err)
    }).finally(()=>{
      loader.hide()
    })
  },
  
  getThemeCardClass(theme) {
      if (theme.id === 1) {
        return "default__theme"; 
      } else if (theme.id === 2) {
        return "green__theme"; 
      } else {
        return "orange__theme";
      }
    },

  activateTheme(theme) {
    const postData={
      theme_id:theme.id
  }
  this.$confirm({
    title:"Activate Theme",
    message:"Do you want to activate this theme?",
    button:{
      yes:"Yes",
      no:"No"
    },
    callback:(confirm)=>{
      if(confirm){
        let loader= this.$loading.show();
        axios.post('v2/theme-configuration/activate-theme', postData)
        .then(response => {
        let message=response.data.message;
          this.$notify(message);
          this.getThemeData()          
        })
        .catch(err => {
          this.$notify(err.response.data.message, "error")
        }).finally(()=>{
          loader.hide()
        });
          }
        }
        
  })
  
},
 
},
created(){
    this.getThemeData()
  }
};
</script>

<style src="../../assets/css/themeselect.css">
</style>
