var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('b-modal', {
    attrs: {
      "id": _vm.theme.id.toString(),
      "hide-footer": "",
      "size": "lg"
    },
    scopedSlots: _vm._u([{
      key: "modal-header",
      fn: function fn() {
        return [_c('div', {
          staticClass: "view-clients-header"
        }, [_c('b-button', {
          attrs: {
            "variant": "close-btn"
          },
          on: {
            "click": _vm.hideModal
          }
        }, [_c('i', {
          staticClass: "fas fa-times"
        })])], 1)];
      },
      proxy: true
    }]),
    model: {
      value: _vm.modalShow,
      callback: function callback($$v) {
        _vm.modalShow = $$v;
      },
      expression: "modalShow"
    }
  }, [_c('b-container', {
    attrs: {
      "fluid": ""
    }
  }, [_c('div', {}, [_c('carsoule-theme', {
    attrs: {
      "themePreviewImages": _vm.theme.theme_preview.image
    }
  }), _c('div', {
    staticClass: "theme-detail"
  }, [_c('h6', {
    staticClass: "mb-3"
  }, [_vm._v("Theme : " + _vm._s(_vm.theme.name))]), _c('div', {}, [_c('h6', [_vm._v(" Description")]), _c('p', [_vm._v(" " + _vm._s(_vm.theme.description) + " ")])]), _c('h6', [_vm._v(" Date: " + _vm._s(_vm.theme.created_at))])]), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [!_vm.theme.isAssigned ? _c('b-button', {
    staticClass: " text-center activate-preview activate-btn",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function click($event) {
        return _vm.activateTheme(_vm.theme);
      }
    }
  }, [_vm._v(" Activate ")]) : _vm._e()], 1)], 1)])], 1)], 1)]);
}
var staticRenderFns = []

export { render, staticRenderFns }