var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "carousel-wrap"
  }, [_c('b-carousel', {
    staticStyle: {
      "text-shadow": "1px 1px 2px #5f5f5f"
    },
    attrs: {
      "interval": 4000,
      "controls": "",
      "indicators": "",
      "background": "#ababab"
    },
    model: {
      value: _vm.slide,
      callback: function callback($$v) {
        _vm.slide = $$v;
      },
      expression: "slide"
    }
  }, _vm._l(_vm.themePreviewImages, function (image, index) {
    return _c('b-carousel-slide', {
      key: index,
      staticClass: "w-100",
      attrs: {
        "src": image
      },
      scopedSlots: _vm._u([{
        key: "img",
        fn: function fn() {
          return [_c('div', {
            staticClass: "img-div"
          }, [_c('img', {
            staticClass: "d-block img-fluid w-100",
            attrs: {
              "src": image,
              "alt": "image slot"
            }
          })])];
        },
        proxy: true
      }], null, true)
    });
  }), 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }