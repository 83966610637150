var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "page-wrapper accInfo-wrap"
  }, [_c('div', {
    staticClass: "container-fluid"
  }, [_vm._m(0), _c('div', {
    staticClass: "card-bg"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, _vm._l(_vm.themeList, function (theme) {
    return _c('div', {
      key: theme.id,
      staticClass: "me-4"
    }, [_c('div', {
      class: [theme.isAssigned ? _vm.getThemeCardClass(theme) : '', 'theme-card default-theme']
    }, [_c('div', {
      staticClass: "theme-card"
    }, [_c('div', {
      staticClass: "backdrop"
    }), _c('img', {
      staticClass: "w-100",
      attrs: {
        "src": theme.thumbnail
      }
    }), _c('b-button', {
      directives: [{
        name: "b-modal",
        rawName: "v-b-modal",
        value: theme.id.toString(),
        expression: "theme.id.toString()"
      }],
      staticClass: "button-preview"
    }, [_vm._v("Preview")])], 1), _c('div', {
      staticClass: "theme-name"
    }, [_c('div', [_vm._v(_vm._s(theme.name))]), !theme.isAssigned ? _c('b-button', {
      staticClass: "activate-btn",
      attrs: {
        "variant": "success"
      },
      on: {
        "click": function click($event) {
          return _vm.activateTheme(theme);
        }
      }
    }, [_vm._v(" Activate ")]) : _vm._e(), theme.isAssigned ? _c('div', {
      staticClass: "theme__active"
    }, [_vm._v("Active")]) : _vm._e()], 1)]), _c('preview-modal', {
      attrs: {
        "theme": theme
      },
      on: {
        "activate-theme": _vm.activateTheme
      }
    })], 1);
  }), 0)])])]);
}
var staticRenderFns = [function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-item-center"
  }, [_c('div', {}, [_c('div', {
    staticClass: "page-title"
  }, [_c('h1', [_vm._v("Select Rep Registration Theme")])])])]);
}]

export { render, staticRenderFns }